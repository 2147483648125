import type { ComponentStyleConfig } from "@chakra-ui/react";

export const Divider: ComponentStyleConfig = {
  sizes: {
    sm: {
      borderBottomWidth: "0.155rem",
    },
    md: {
      borderBottomWidth: "3px",
    },
  },
  defaultProps: {
    size: "md",
  },
};
