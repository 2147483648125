export const colorScheme = {
  greenMurchison: "#007749",
  blueAIT: "#003A76",
  orangeTZ: "#FF8200",
  redAIT: "#E41E2D",
  blueUTE: "#005C97",
};

export const stepperScheme = {
  colorScheme,
};
