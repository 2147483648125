import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const sizes = {
  drawerUser: definePartsStyle({
    dialog: defineStyle({
      maxWidth: "400px",
      maxHeight: "100%",
      // marginTop: "2.75%",
    }),
  }),
};

export const drawerTheme = defineMultiStyleConfig({
  sizes,
});
