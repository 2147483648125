import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { lazy } from "react";
// import { mockData } from "utils/Mocks/mockOCGenerator";

const Private = lazy(() => import("./Pages/Private/Private"));
const Landing = lazy(() => import("./Pages/Landing/Landing"));

/**
 * Renders the main App component with authentication check and routing logic.
 *
 * @return {JSX.Element} The rendered main App component
 */
function App() {
  const isAuthenticated = useIsAuthenticated();
  const routerUrl = process.env.REACT_APP_ROUTER_URL;
  // console.log(JSON.stringify(mockData, null, 2));

  return (
    <div className="App">
      <Routes>
        <Route
          path={routerUrl + "/*"}
          element={
            isAuthenticated ? (
              <Private />
            ) : (
              <div className="appContainer">
                <Landing />
              </div>
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
