import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { checkboxTheme } from "./utils/Checkbox";
import { drawerTheme } from "./utils/Drawers";
import { FormLabel } from "./utils/FormLabel";
import { Divider } from "./utils/Divider";
import { colorScheme, stepperScheme } from "utils/StepperTheme";

export const theme = extendTheme({
  components: {
    Checkbox: checkboxTheme,
    Drawer: drawerTheme,
    FormLabel,
    Divider,
    Stepper: stepperScheme,
  },
  colors: colorScheme,
});

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </ChakraProvider>
  </BrowserRouter>
);
