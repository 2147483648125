const redirectURL = process.env.REACT_APP_AZURE_REDIRECT_URL;
const tenantAzureId = process.env.REACT_APP_AZURE_ID_TENANT || "";
const clientAzureId = process.env.REACT_APP_AZURE_CLIENT_ID || "";

export const msalConfig = {
  auth: {
    clientId: clientAzureId,
    authority: `https://login.microsoftonline.com/${tenantAzureId}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: redirectURL,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [`User.Read.All`, `Group.Read.All`, `User.Read`],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};

export const getAccessToken = async (instance, account) => {
  const request = {
    ...loginRequest,
    account: account,
  };
  try {
    const response = await instance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (e) {
    console.log("Error acquiring token:", e);
  }
};
